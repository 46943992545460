<template>
  <div class="head-container">
    <el-select
      v-model="status"
      clearable
      placeholder="状态"
      class="filter-item"
      style="width: 130px"
      @change="selectChangeHandle"
    >
      <el-option
        v-for="item in queryTypeOptions"
        :key="item.key"
        :label="item.display_name"
        :value="item.key"
      />
    </el-select>
    <el-button
      v-if="getPermission()"
      class="filter-item"
      size="mini" type="primary" icon="el-icon-plus" @click="addHandle">新增弹窗通知</el-button>
  </div>
</template>

<script>
  import checkPermission from '@/utils/permission'
export default {
  data() {
    return {
      queryTypeOptions: [
        { key: 0, display_name: '不显示' },
        { key: 1, display_name: '显示' },
      ],
      status: '',
    }
  },
  methods: {
    selectChangeHandle(){
      this.$emit('changeHandle', this.status);
    },
    addHandle(){
      this.$emit('addHandle')
    },
    getPermission(){
      return checkPermission(['ADMIN','TPopUpNotice_ALL','TPopUpNotice_CREATE']);
    }
  }
}
</script>
