<template>
  <div class="app-container">
    <eHeader @changeHandle="changeHandle" @addHandle="addHandle" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="lang" label="语言">
        <template slot-scope="scope">
          <span>{{getLang(scope.row.lang)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="标题"/>

      <el-table-column label="起始时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.startTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="结束时间">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.endTime)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="getLookFlag()"
            @click="editHandle(scope.row)"
            size="mini"
            type="success"
          >编辑
          </el-button>
          <el-button
            v-if="getDeleteFlag()"
            @click="deleteHandle(scope.row)"
            size="mini"
            type="danger"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
    <add-or-edit-dialog
      :is-add="isAdd"
      :dialog-show="dialogShow"
      :row-data="rowData"
      @refresh-list="refreshlist"
      @close-dialog="closeHandle"
    ></add-or-edit-dialog>
  </div>
</template>

<script>
  import { deleteNoticeDialog } from '@/api/notificationManager/noticeDialog'
  import addOrEditDialog from '@/components/notificationManager/noticeDialog/addOrEditDialog'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/notificationManager/noticeDialog/header'

  export default {
    name: 'noticeDialog',
    components: {
      eHeader,
      addOrEditDialog,
    },
    mixins: [initData],
    data() {
      return {
        delLoading: false,
        rowData: {},
        btnLoading: false,
        status: '',
        dialogShow: false,
        isAdd: false,
      }
    },
    created() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      beforeInit() {
        this.url = 'crm/tPopUpNotice'
        const sort = 'id,desc'
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort,
          status: this.status,
        }
        return true
      },
      getLookFlag() {
        return checkPermission(['ADMIN','TPublicNotice_ALL','TPublicNotice_EDIT']);
      },
      getDeleteFlag() {
        return checkPermission(['ADMIN','TPopUpNotice_ALL','TPopUpNotice_DELETE']);
      },
      getStatus(status){
        switch (status) {
          case 0:
            return '不显示';
          case 1:
            return '显示';
        }
      },
      changeHandle(status){
        this.status = this.status;
        this.page = 0;
        this.init();
      },
      editHandle(row){
        this.isAdd = false;
        this.rowData = row;
        this.dialogShow = true;
      },
      deleteHandle(row){
        this.$confirm(`是否要删除本条通知弹框`,'提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //点击确定的操作(调用接口)
          deleteNoticeDialog({id:row.id}).then(res => {
            this.init()
            this.$notify({
              title: '删除成功',
              type: 'success',
              duration: 1000
            })
          }).catch(err => {

          })
        }).catch(() => {
          //几点取消的提示
        });
      },
      closeHandle(){
        this.dialogShow = false;
      },
      addHandle(){
        this.isAdd = true;
        this.dialogShow = true;
      },
      refreshlist(){
        this.init();
      },
      getLang(lang){
        return lang == 'zh_CN' ? '中文': '英文';
      }
    }
  }
</script>

<style scoped>

</style>
