<template>
  <el-dialog
    :append-to-body="true"
    :visible="dialogShow"
    :before-close="beforeCloseHandle"
    :title="isAdd ? '新增': '编辑' + '通知弹窗'"
    width="600px"
    class="add-or-edit-notice-dialog"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      size="small"
      label-width="80px"
    >
      <el-form-item prop="lang" label="语言">
        <el-radio v-model="form.lang" label="zh_CN">中文</el-radio>
        <el-radio v-model="form.lang" label="en_US">EngLish</el-radio>
      </el-form-item>
      <el-form-item prop="title" label="标题">
        <el-input
          placeholder="请输入"
          v-model="form.title"
          :maxlength="titleWorldsCount"
          :show-word-limit="true"
        />
      </el-form-item>
      <el-form-item label="有效期">
        <el-row :gutter="10">
          <el-col :span="5">
            <el-date-picker
              style="width: 140px"
              v-model="today"
              type="date"
              :disabled="true"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="2" :offset="3">
            <div>至</div>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="endTime">
              <el-date-picker
                v-model="form.endTime"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio v-model="form.status" :label="1">显示</el-radio>
        <el-radio v-model="form.status" :label="0">不显示</el-radio>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <quill-editor
          @catchData="catchData"
          :defaultValues="form.content"
        ></quill-editor>
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click.stop="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import quillEditor from '@/components/tools/quillEditor'
  import { parseTime } from '../../../utils'
  import { addNoticeDialog,editNoticeDialog } from '@/api/notificationManager/noticeDialog'
export default {
    components:{
      quillEditor,
    },
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
    dialogShow:{
      type: Boolean,
      default: false
    },
    rowData:{
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        id: '',
        lang: 'zh_CN',
        title: '',
        endTime: '',
        status: 1,
        content: '',
      },

      rules:{
        title:[
          { required: true, message: '请填写标题', trigger: 'blur' },
        ],
        endTime:[
          { required: true, message: '请选择结束日期', trigger: 'change' },
        ],
        content:[
          { required: true, message: '请输入内容', },
        ]
      },
    }
  },
  watch:{
    rowData:{
      handler(newValue){

        if(Object.keys(newValue).length > 0 && this.isAdd == false){
          this.assignmentFormCom(newValue);
        }
      },
      deep: true,
      immediate: true,
    },
    dialogShow(newValue){
      this.$nextTick(() => {
        if(newValue && this.isAdd){
          this.clearForm();
          this.$refs.form && this.$refs.form.clearValidate();
        }
        if(newValue && !this.isAdd){
          this.assignmentFormCom(this.rowData);
          this.$refs.form && this.$refs.form.clearValidate();
        }
      });
    },
  },
  computed: {
    typeDisabled(){
      return !this.isAdd ? true : false;
    },
    today(){
      let now = new Date();
      return parseTime(now).split(' ')[0];
    },
    titleWorldsCount(){
      if(this.form.lang == 'zh_CN'){
         return 20;
      }else {
        return 60;
      }
    }
  },
  methods: {
    channelNameEnInputHandle(){
      this.form.nameEn = limitEnAndSpace(this.form.nameEn);
    },
    clearForm(){
      this.form = {
        id: '',
        lang: 'zh_CN',
        title: '',
        endTime: '',
        status: 1,
        content: '',
      };
    },
    cancel() {
      this.close();
    },
    close(){
      this.$emit('close-dialog');
    },
    beforeCloseHandle(){
      this.close();
    },
    assignmentFormCom(data){
      this.form = data;
    },
    doSubmit() {
      this.$refs.form.validate((valid) => {
        if(valid){
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    doAdd() {
      let params = {
        title: this.form.title,
        lang: this.form.lang,
        endTime: this.form.endTime,
        status: this.form.status,
        content: this.form.content,
      };
      this.loading = true;
      addNoticeDialog(params).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        });
        this.loading = false
        this.refresh();
        this.close();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      let params = {
        id: this.form.id,
        title: this.form.title,
        lang: this.form.lang,
        endTime: this.form.endTime,
        status: this.form.status,
        content: this.form.content,
      }
      this.loading = true;
      editNoticeDialog(params).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.refresh();
        this.close();
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.$refs['form'].clearValidate();
      this.$refs['form'].resetFields();
    },
    catchData(value) {
      //在这里接受子组件传过来的参数，赋值给data里的参数
      this.form.content = value
    },
    refresh(){
      this.$emit('refresh-list');
    }
  }
}
</script>
<style lang="less" scoped>
.add-or-edit-notice-dialog {
  .money-prefix {
    width: 80px;
  }
  .time-box {
    width: 100%;
  }
  .time-spilt {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .input-box-rate {
    width: 380px;
    position: relative;
    .ratesuffix {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
  }
  .upload-img {
    width: 50px;
    height: 50px;
  }
  .uppload-btn {
    color: skyblue;
  }
  .el-form-item__label {
    word-break: break-all;
  }
  .delete {
    cursor: pointer;
  }
  .icon-class {
    width: 100px;
    margin-top: 20px;
  }
}
</style>
