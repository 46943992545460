import request from '@/utils/request'

export function addNoticeDialog(data) {
  return request({
    url: 'crm/tPopUpNotice',
    method: 'post',
    data,
  })
}

export function editNoticeDialog(data) {
  return request({
    url: 'crm/tPopUpNotice',
    method: 'put',
    data,
  })
}

export function deleteNoticeDialog(data) {
  return request({
    url: `crm/tPopUpNotice/${data.id}`,
    method: 'delete',
  })
}
